import type { ReactNode } from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  h2Style: {
    color: theme.palette.grey[900],
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: theme.spacing(0.25),
    borderLeft: `${theme.spacing(0.5)}px solid ${theme.palette.primary.main}`,
    paddingLeft: theme.spacing(0.75),
    margin: theme.spacing(2, 0, 4),
  },
}));

export type H2Props = {
  children: ReactNode;
};

export const H2 = ({ children }: H2Props): JSX.Element => {
  const classes = useStyles();
  return (
    <Typography variant="h2" className={classes.h2Style}>
      {children}
    </Typography>
  );
};
