import type { ReactNode } from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  h3Style: {
    color: theme.palette.grey[900],
    padding: theme.spacing(1),
    fontSize: 16,
    fontWeight: 'bold',
    margin: theme.spacing(4, 0),
    backgroundColor: theme.palette.grey[100],
  },
}));

export type H3Props = {
  children: ReactNode;
};

export const H3 = ({ children }: H3Props): JSX.Element => {
  const classes = useStyles();
  return (
    <Typography variant="h3" className={classes.h3Style}>
      {children}
    </Typography>
  );
};
