import styled from 'styled-components';

export type SpacerProps = {
  size?: number;
};

export const Spacer = styled.hr<SpacerProps>`
  border: none;
  margin: 0;
  height: ${({ size = 1 }) => `${size * 4}px`};
`;
