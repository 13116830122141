import type { ReactChild } from 'react';
import styled from 'styled-components';

export const Label = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: #555555;
  margin-bottom: 0px;
`;

const Value = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #3c3c3c;
  border-bottom: 1.5px solid #cccccc;
  margin-top: 5px;
  padding-bottom: 5px;
  white-space: pre-line;
`;

export type LabeledValueProps = {
  label: string;
  value: string | ReactChild | null | undefined;
};

export const LabeledValue = ({
  label,
  value,
}: LabeledValueProps): JSX.Element => {
  return (
    <div>
      <Label>{label}</Label>
      <Value>{value || '-'}</Value>
    </div>
  );
};
