import type { ReactNode } from 'react';
import styled from 'styled-components';

export type H1Props = {
  children: ReactNode;
};

export const H1: (props: H1Props) => JSX.Element = styled.h1`
  font-size: 28px;
`;
